<template>
<div class="editpassword-page">
    <div class="pass-body">
        <van-field v-model="password" placeholder="请输入旧密码" type="password" label="旧密码" />
        <van-field v-model="newpassword" placeholder="请输入新密码" type="password" label="新密码" />
        <van-field v-model="newpassword2" placeholder="请输入新密码" type="password" label="新密码" />
    </div>
    <div class="save-pass">
        <van-button color="#DF5F0B" :block=true>修改密码</van-button>
    </div>
</div>
</template>

<script setup>
import { ref } from "vue";
let password = ref('');
let newpassword = ref('');
let newpassword2 = ref('');
</script>

<style lang="scss" scoped>
.editpassword-page{
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;
    .pass-body{
        min-height: 60px;
        background: #fff;
    }
    .save-pass{
        padding: 15px;
    }
}
</style>